import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import logo from './pictures/Microbiz short logo.png';


function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Navbar expand="lg fixed-top" style={{ paddingTop: '24px', paddingBottom: '10px', paddingLeft: '6rem', paddingRight: '6rem', backgroundColor: '#095390' }}>
      <div className='d-flex justify-content-between align-items-top w-100'>
        <Navbar.Brand href="/" style={{marginRight: '0', flexGrow: '1'}}>
          <img src={logo} alt="Microbiz Logo" style={{ maxWidth: '18%', height: 'auto' }} />
        </Navbar.Brand>
        <div className='d-block' style={{paddingTop: '2%', flexGrow: '1'}}>
        <Navbar.Toggle 
          aria-controls="navbarNavDropdown" 
          onClick={toggleMenu} 
          style={{ border: 'none' }}
        />
        <Navbar.Collapse 
          id="navbarNavDropdown" 
          className={`justify-content-between ${isMenuOpen ? 'show' : 'collapse'}`} 
          style={{ transition: 'max-height 0.3s ease-in-out', maxHeight: isMenuOpen ? '300px' : '0', flexDirection: isMenuOpen ? 'column' : 'row' }}
        >
          <Nav className="ml-auto" style={{ width: '100%' }}>
            <div className="d-flex flex-column flex-lg-row align-items-lg-center w-100 justify-content-between">
              <div className="d-flex flex-column flex-lg-row">
                <Nav.Link as={Link} to="/home" style={{ color: 'white', opacity: '0.5' }}><p className='d-inline' style={{fontSize: '100%'}}>Home</p></Nav.Link>
                <Nav.Link as={Link} to="/company" style={{ color: 'white', opacity: '0.5' }}><p className='d-inline' style={{fontSize: '100%'}}>Company</p></Nav.Link>
                <NavDropdown title={<span style={{ color: 'white', opacity: '0.5' }}><p className='d-inline' style={{fontSize: '100%'}}>Personal</p></span>} id="personal-dropdown" className="custom-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/take-loan">Take Loan</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/save-money">Save Money</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={<span style={{ color: 'white', opacity: '0.5' }}><p className='d-inline' style={{fontSize: '100%'}}>Business</p></span>} id="business-dropdown" className="custom-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/sme-loan">SME Loan</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/retail-loan">Retail Loan</NavDropdown.Item>
                </NavDropdown>
              </div>
              <div className="d-flex flex-column flex-lg-row align-items-lg-center ml-lg-auto">
                <Nav.Link as={Link} to="/contact-us" style={{ color: 'white', opacity: '0.5' }}><p className='d-inline' style={{fontSize: '100%'}}>Contact Us</p></Nav.Link>
                <Button style={{ backgroundColor: '#ffffff', color: '#000000', border: '#ffffff', marginLeft: '10px' }}><p className='d-inline' style={{fontSize: '100%'}}>Internet Banking</p></Button>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  );
}

export default Header;

import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';

const FAQ = () => {
  // State to track the currently active accordion item
  const [activeKey, setActiveKey] = useState('0');

  // Handle click to set the active accordion item
  const handleToggle = (key) => {
    setActiveKey(key === activeKey ? '' : key); // Toggle or close if the same key is clicked
  };

  return (
    <div className="container mt-4" style={{backgroundColor: '#1C2F5A', width:'80%', paddingTop: '3rem', paddingBottom: '3rem',marginBottom: '3rem', paddingLeft: '8rem', paddingRight: '8rem', borderRadius: '10px'}}>
          <Row className="mb-4">
        <p  style={{
            color: '#095390',
            backgroundColor: '#ffffff',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>FAQ</p>
      </Row>
      <Row className="text-center mb-4">
        <Col>
          <h5 style={{color: '#ffffff'}}>Explore Our Comprehensive Suite of Services</h5>
        </Col>
      </Row>
      <div className="accordion" id="faqAccordion" style={{display: 'grid', gap: '1rem'}}>
        <div className="card gradient-border" style={{backgroundColor: 'rgba(9, 83, 144, 0.15)', paddingTop:'1%', paddingBottom: '1%'}}>
          <div className="card-header" id="headingTwo" style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', textAlign: 'center', border: 'none'}}>
            <div style={{color: '#ffffff', alignSelf:'center'}}>
              How do I track my order?
            </div>
            <button
                className="btn btn-link white-button"
                type="button"
                aria-expanded={activeKey === '1'}
                onClick={() => handleToggle('1')}
              >
                
                <i className={`bi ${activeKey === '1' ? 'bi-chevron-up' : 'bi-chevron-down'} float-right`}></i>
              </button>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${activeKey === '1' ? 'show' : ''}`}
            aria-labelledby="headingTwo"
          >
            <div className="card-body" style={{border: 'none', borderTop: '1px solid', borderColor:'#095390'}}>
              <span  style={{color: '#ffffff', fontSize: '85%'}}>
              You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>
        <div className="card gradient-border" style={{backgroundColor: 'rgba(9, 83, 144, 0.15)', paddingTop:'1%', paddingBottom: '1%'}}>
          <div className="card-header" id="headingTwo" style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', textAlign: 'center', border: 'none'}}>
            <div style={{color: '#ffffff', alignSelf:'center'}}>
              How do I track my order?
            </div>
            <button
                className="btn btn-link white-button"
                type="button"
                aria-expanded={activeKey === '2'}
                onClick={() => handleToggle('2')}
              >
                
                <i className={`bi ${activeKey === '2' ? 'bi-chevron-up' : 'bi-chevron-down'} float-right`}></i>
              </button>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${activeKey === '2' ? 'show' : ''}`}
            aria-labelledby="headingTwo"
          >
            <div className="card-body" style={{border: 'none', borderTop: '1px solid', borderColor:'#095390'}}>
              <span  style={{color: '#ffffff', fontSize: '85%'}}>
              You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>
        <div className="card gradient-border" style={{backgroundColor: 'rgba(9, 83, 144, 0.15)', paddingTop:'1%', paddingBottom: '1%'}}>
          <div className="card-header" id="headingTwo" style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', textAlign: 'center', border: 'none'}}>
            <div style={{color: '#ffffff', alignSelf:'center'}}>
              How do I track my order?
            </div>
            <button
                className="btn btn-link white-button"
                type="button"
                aria-expanded={activeKey === '3'}
                onClick={() => handleToggle('3')}
              >
                
                <i className={`bi ${activeKey === '3' ? 'bi-chevron-up' : 'bi-chevron-down'} float-right`}></i>
              </button>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${activeKey === '3' ? 'show' : ''}`}
            aria-labelledby="headingTwo"
          >
            <div className="card-body" style={{border: 'none', borderTop: '1px solid', borderColor:'#095390'}}>
              <span  style={{color: '#ffffff', fontSize: '85%'}}>
              You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>
        <div className="card gradient-border" style={{backgroundColor: 'rgba(9, 83, 144, 0.15)', paddingTop:'1%', paddingBottom: '1%'}}>
          <div className="card-header" id="headingTwo" style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', textAlign: 'center', border: 'none'}}>
            <div style={{color: '#ffffff', alignSelf:'center'}}>
              How do I track my order?
            </div>
            <button
                className="btn btn-link white-button"
                type="button"
                aria-expanded={activeKey === '4'}
                onClick={() => handleToggle('4')}
              >
                
                <i className={`bi ${activeKey === '4' ? 'bi-chevron-up' : 'bi-chevron-down'} float-right`}></i>
              </button>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${activeKey === '4' ? 'show' : ''}`}
            aria-labelledby="headingTwo"
          >
            <div className="card-body" style={{border: 'none', borderTop: '1px solid', borderColor:'#095390'}}>
              <span  style={{color: '#ffffff', fontSize: '85%'}}>
              You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>
        <div className="card gradient-border" style={{backgroundColor: 'rgba(9, 83, 144, 0.15)', paddingTop:'1%', paddingBottom: '1%'}}>
          <div className="card-header" id="headingTwo" style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', textAlign: 'center', border: 'none'}}>
            <div style={{color: '#ffffff', alignSelf:'center'}}>
              How do I track my order?
            </div>
            <button
                className="btn btn-link white-button"
                type="button"
                aria-expanded={activeKey === '5'}
                onClick={() => handleToggle('5')}
              >
                
                <i className={`bi ${activeKey === '5' ? 'bi-chevron-up' : 'bi-chevron-down'} float-right`}></i>
              </button>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${activeKey === '5' ? 'show' : ''}`}
            aria-labelledby="headingTwo"
          >
            <div className="card-body" style={{border: 'none', borderTop: '1px solid', borderColor:'#095390'}}>
              <span  style={{color: '#ffffff', fontSize: '85%'}}>
              You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;

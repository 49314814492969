import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import appmockup from './pictures/360shots_so.png';
import image1 from './pictures/WhatsApp Image 2024-03-04 at 2.06.06 PM.jpeg';
import image2 from './pictures/WhatsApp Image 2024-05-09 at 10.36.22_3585c6ef.jpg';
import { ReactComponent as GooglePlayIcon } from './pictures/playstore 1.svg';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ReactComponent as Cbn } from './pictures/cbn.svg';
import { ReactComponent as Ndic } from './pictures/ndic.svg';
import { ReactComponent as Transfer } from './pictures/switch-horizontal.svg';
import MultiCarousel from './Multicarousel';
import logo1 from './pictures/remita-logo.jpg';
import logo2 from './pictures/zenith-bank-logo.jpg';
import logo3 from './pictures/fidelity-bank-logo.jpg';
import logo4 from './pictures/nibss-logo.jpg';
import logo5 from './pictures/access-bank-logo.jpg';
import logo6 from './pictures/partners-worldwide-logo.jpg';
import logo7 from './pictures/crc-cb-logo.jpg';
import logo8 from './pictures/first-central-logo (1).jpg';
import logo9 from './pictures/uba-logo.jpg';
import logo10 from './pictures/peak-mfi-logo.jpg';

function Home() {
  return (
    <div className='homepage'>
    <div className='herosection' style={{ backgroundColor: '#095390', padding: '0', marginTop: '50px' }}>
      <Container fluid className='d-flex flex-column' style={{ gap: '1%'}}>
        <Row className='align-items-center' style={{ paddingLeft: '6rem', paddingRight: '6rem', paddingBottom: '1rem', paddingTop: '5rem'}}>
          <Col md={6} className='text-white d-flex flex-column align-items-start' style={{ padding: '1rem' }}>
            <h1 style={{ textAlign: 'left', lineHeight: 1.3 }}>
              <span style={{ color: 'red' }}>Empowering</span> Your Financial Future
            </h1>
            <p style={{ textAlign: 'left', lineHeight: 1.7 }}>
              Discover affordable loans and secure savings options, supported by expert guidance to help you achieve your dreams.
            </p>
            <div className='d-flex flex-md-row justify-content-center' style={{ gap: '1rem'}}>
              <Button variant='light' style={{ color: '#000000', borderColor: '#095390' }}>
                <i className="fab fa-apple fa-xl" style={{ paddingRight: '0.5rem' }}></i> <p className='d-inline' style={{fontSize: '80%'}}>App Store</p>
              </Button>
              <Button variant='light' style={{ color: '#000000', borderColor: '#095390'}}>
                <GooglePlayIcon width="24" height="24" /> <p className='d-inline' style={{fontSize: '80%'}}>Play Store</p>
              </Button>
            </div>
            <div className='d-flex flex-wrap justify-content-center' style={{ gap: '1rem', marginTop: '1rem' }}>
              <Cbn width="120" height="70" />
              <Ndic width="120" height="70" />
            </div>
          </Col>
          <Col md={6} className='d-flex align-items-center justify-content-center'>
            <img
              src={appmockup}
              alt="App Mockup"
              style={{
                width: '80%',
                maxWidth: '50rem',  // Max width to maintain the size you want on larger screens
                height: 'auto',
                objectFit: 'contain',
                transform: 'scale(1.06)', 
                transformOrigin: 'center',
              }}
            />

          </Col>
        </Row>
      </Container>
    </div>

    <div className='secondsection' style={{ paddingRight: '8%', paddingLeft: '8%', marginTop: '100px', marginBottom: '100px' }}>
      <Container fluid style={{ gap: '40px' }}>
        <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%' }}>
          <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
            <img
              src={image1}
              alt="App Mockup"
              style={{
                width: '100%',
                maxWidth: '40rem',
                height: 'auto',
                objectFit: 'cover',
                objectPosition: 'top',
                borderRadius: '10px'
              }}
            />
          </Col>
          <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
            <h1 style={{ textAlign: 'left', color: 'black', lineHeight: 1.3 }}>
              Get Your <span style={{ color: 'red' }}>Bank Account</span> In Minutes!
            </h1>
            <p style={{ textAlign: 'left', color: 'black', lineHeight: 1.7 }}>
              Opening and operating a bank account has never been this easy. We have removed all the hassles. Just provide your relevant information, and you have a bank account of your own.
            </p>
            <div className='d-flex flex-column flex-md-row' style={{ gap: '1rem' }}>
              <Button style={{ backgroundColor: '#095390', color: '#ffffff', border: '#095390', width: '10rem' }}>
                Open Account
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

    <Container className="features" style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: '6rem', paddingRight: '6rem', marginBottom: '1rem' }}>
    <Row className="mb-4">
        <p  style={{
            color: '#095390',
            backgroundColor: '#F4F4F4',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>Features</p>
      </Row>
      <Row className="text-center mb-4">
        <Col>
          <h5>Explore Our Comprehensive Suite of Services</h5>
        </Col>
      </Row>
      <Row style={{ padding: '1.5%', backgroundColor: '#1C2F5A', borderRadius: '10px' }} className="justify-content-center">
      <Col xs={12} sm={6} md={5} lg={4}  className="mb-3">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Easy Transfer</p>
            <span style={{ fontSize: '0.8rem' }}>Facilitate seamless transfers of funds between your accounts or to others, ensuring swift processing and minimal complexity.</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4}  className="mb-3">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Easy Transfer</p>
            <span style={{ fontSize: '0.8rem' }}>Facilitate seamless transfers of funds between your accounts or to others, ensuring swift processing and minimal complexity.</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4}  className="mb-3">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Easy Transfer</p>
            <span style={{ fontSize: '0.8rem' }}>Facilitate seamless transfers of funds between your accounts or to others, ensuring swift processing and minimal complexity.</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4}  className="mb-3">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Easy Transfer</p>
            <span style={{ fontSize: '0.8rem' }}>Facilitate seamless transfers of funds between your accounts or to others, ensuring swift processing and minimal complexity.</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4}  className="mb-3">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Easy Transfer</p>
            <span style={{ fontSize: '0.8rem' }}>Facilitate seamless transfers of funds between your accounts or to others, ensuring swift processing and minimal complexity.</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4} className="mb-3">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Easy Transfer</p>
            <span style={{ fontSize: '0.8rem' }}>Facilitate seamless transfers of funds between your accounts or to others, ensuring swift processing and minimal complexity.</span>
          </div>
        </Col>
       
        
        {/* Repeat for other features */}
      </Row>
    </Container>

    <div className='fourthsection' style={{ paddingRight: '8%', paddingLeft: '8%', marginTop: '100px', marginBottom: '100px' }}>
      <Container fluid style={{ gap: '40px' }}>
        <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%' }}>
          <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
            <img
              src={image2}
              alt="App Mockup"
              style={{
                width: '200%',
                maxWidth: '40rem',
                height: '22rem',
                objectFit: 'cover',
                objectPosition: 'top',
                borderRadius: '10px'
              }}
            />
          </Col>
          <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
            <h1 style={{ textAlign: 'left', color: 'black', lineHeight: 1.3 }}>
            Make <span style={{ color: 'red' }}>Good Returns</span> On Your Savings!
            </h1>
            <p style={{ textAlign: 'left', color: 'black', lineHeight: 1.7 }}>
            Save your money with Microbiz and enjoy higher interest rates on your fixed deposits. Making simple money has never been this sweet.
            </p>
            <div className='d-flex flex-column flex-md-row' style={{ gap: '1rem' }}>
              <Button style={{ backgroundColor: '#095390', color: '#ffffff', border: '#095390', width: '10rem' }}>
                Start Saving
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div className='testimonials' style={{ backgroundColor: '#fafafa', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <div style={{ backgroundColor: '#fafafa', paddingTop: '2%', paddingBottom: '5%', width: '100%', textAlign: 'center' }}>
        <Row >
          <p style={{
              color: '#095390',
              backgroundColor: '#fafafa',
              padding: '1rem',
              borderRadius: '4px',
              textAlign: 'center',
              margin: '0 auto',
              display: 'inline-block',
              width: 'auto'
            }}>
            Testimonials
          </p>
        </Row>
        <Row className="text-center mb-4">
          <Col>
            <h5>See What Our Customers Have to Say</h5>
          </Col>
        </Row>
        <Row className='d-flex' style={{ justifyContent: 'center', width: '100%', margin: '0 auto', overflow: 'hidden' }}>
          <MultiCarousel />
        </Row>
      </div>
    </div>



    <div className='partners' style={{paddingTop: '2%'}}>
    <Container className="features" style={{ justifyContent: 'center', alignItems: 'center',marginBottom: '1rem' }}>
    <Row className="mb-4" style={{ justifyContent: 'center' }}>
        <p  style={{
            color: '#095390',
            backgroundColor: '#F4F4F4',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>Our Partners</p>
      </Row>
        <Row className="text-center mb-4">
          <Col>
            <h5>Trusted Partnerships for Exceptional Service</h5>
          </Col>
        </Row>
      <Row className="justify-content-between mb-4">
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo1} alt="Partner 1" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo2} alt="Partner 2" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo3} alt="Partner 3" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo4} alt="Partner 4" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo5} alt="Partner 5" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
      </Row>
      <Row  className="justify-content-between">
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo6} alt="Partner 6" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo7} alt="Partner 7" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo8} alt="Partner 8" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo9} alt="Partner 9" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo10} alt="Partner 10" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
      </Row>
      </Container>
    </div>
    <div className='FAQ'>

    </div>
    <div>

    </div>
  </div>
);
}

export default Home;

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import pfp1 from './pictures/daisy-okpebholo.jpg';
import pfp2 from './pictures/ahmed-usman.jpg';
import pfp3 from './pictures/gift-akaruese.jpg';
import pfp4 from './pictures/default-avatar.png';
import pfp5 from './pictures/medinatu-onyoiza.jpg';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CustomCarousel = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div style={{ width: '90%' }}>
        <Carousel
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="item" style={{padding: '10px', border: 'solid', borderWidth: '1px', borderRadius: '10px', borderColor: '#e6e6e6', width: '95%', margin: 'auto'}}>
            <div>
              <img
                src={pfp1}
                alt="Daisy Okpebholo"
                style={{
                  width: '20%',
                  maxWidth: '2rem',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '100%',
                  padding: '0',
                  marginRight: '0.5%'
                }}
              />
              <span style={{ fontWeight: '600', width: 'auto', padding: '0' }}>Daisy Okpebholo</span>
            </div>
            <p><span style={{ fontWeight: 'lighter', fontSize: '85%' }}> I appreciate Microbiz. Your services to me were properly done, even though I delayed on my part but you really did come through for me. </span></p>
          </div>
          <div className="item" style={{padding: '10px', border: 'solid', borderWidth: '1px', borderRadius: '10px', borderColor: '#e6e6e6', width: '95%', margin: 'auto'}}>
            <div>
              <img
                src={pfp2}
                alt="Dr. Ahmed Usman"
                style={{
                  width: '20%',
                  maxWidth: '2rem',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '100%',
                  padding: '0',
                  marginRight: '0.5%'
                }}
              />
              <span style={{ fontWeight: '600', width: 'auto', padding: '0' }}>Dr. Ahmed Usman</span>
            </div>
            <p><span style={{ fontWeight: 'lighter', fontSize: '85%' }}> Everything went successfully. I really do appreciate your timely services. I am glad I came to you and I wasn't disappointed. God bless you richly. </span></p>
          </div>
          <div className="item" style={{padding: '10px', border: 'solid', borderWidth: '1px', borderRadius: '10px', borderColor: '#e6e6e6', width: '95%', margin: 'auto'}}>
            <div>
              <img
                src={pfp3}
                alt="Gift Akaruese"
                style={{
                  width: '20%',
                  maxWidth: '2rem',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '100%',
                  padding: '0',
                  marginRight: '0.5%'
                }}
              />
              <span style={{ fontWeight: '600', width: 'auto', padding: '0' }}>Gift Akaruese</span>
            </div>
            <p><span style={{ fontWeight: 'lighter', fontSize: '85%' }}> I received  a professional and helpful service on my first loan request with Microbiz. Every question I had was answered in a timely manner. </span></p>
          </div>
          <div className="item" style={{padding: '10px', border: 'solid', borderWidth: '1px', borderRadius: '10px', borderColor: '#e6e6e6', width: '95%', margin: 'auto'}}>
            <div>
              <img
                src={pfp4}
                alt="Osuagwu Kingsley"
                style={{
                  width: '20%',
                  maxWidth: '2rem',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '100%',
                  padding: '0',
                  marginRight: '0.5%'
                }}
              />
              <span style={{ fontWeight: '600', width: 'auto', padding: '0' }}>Osuagwu Kingsley</span>
            </div>
            <p><span style={{ fontWeight: 'lighter', fontSize: '85%' }}> Thank you so much for your services so far. You made it possible for me to succeed. I will be recommending you guys to my people. </span></p>
          </div>
          <div className="item" style={{padding: '10px', border: 'solid', borderWidth: '1px', borderRadius: '10px', borderColor: '#e6e6e6', width: '95%', margin: 'auto'}}>
            <div>
              <img
                src={pfp5}
                alt="Medinatu Onyoiza"
                style={{
                  width: '20%',
                  maxWidth: '2rem',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '100%',
                  padding: '0',
                  marginRight: '0.5%'
                }}
              />
              <span style={{ fontWeight: '600', width: 'auto', padding: '0' }}>Medinatu Onyoiza</span>
            </div>
            <p><span style={{ fontWeight: 'lighter', fontSize: '85%' }}> Yes I was successful. Thank you so much for your help. God bless you richly. I will send some pictures so that you can rejoice with me. </span></p>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default CustomCarousel;


// Add this to your CSS file



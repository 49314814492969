import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import SMELoan from './components/SMELoan';
import RetailLoan from './components/RetailLoan';
import Company from './components/Company';
import Home from './components/Home';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FAQ from './components/FAQ';


function App() {
  return (
   <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/home" element={<Home/>} />
            <Route path="/sme-loan" element={<SMELoan />} />
            <Route path="/retail-loan" element={<RetailLoan />} />
            <Route path="/company" element={<Company />} />
          </Routes>
        </main>
        <FAQ/>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import logo from './pictures/logo.png';
import 'bootstrap-icons/font/bootstrap-icons.css';
import pic1 from './pictures/gr2.jpg'
import pic2 from './pictures/gr3.jpg'

function Footer() {
  return (
    <footer className="text-dark text-center py-5 my-5" style={{ backgroundColor: '#DAE5EF' }}>
      <Container className='my-5'>
        <Row className="my-4">
          <Col className="d-flex justify-content-start">
            <img
              src={logo}
              alt="Microbiz logo"
              style={{ maxWidth: '150px', height: 'auto' }}
            />
          </Col>
        </Row>

        {/* Second Row: Text Columns with Ordering */}
        <Row>
          <Col className="text-start" xs={{ order: 1 }}>
            <p className="d-flex align-items-center" style={{ fontSize: '14px', justifyContent: 'flex-start' }}>
              <i className="bi bi-geo-alt-fill me-2" style={{ fontSize: '16px' }}></i>
              <span>
                1st floor, Nicon Plaza, Central Business District, Abuja, Federal Capital Territory
              </span>
            </p>
            <p className="d-flex align-items-center" style={{ fontSize: '14px', justifyContent: 'flex-start' }}>
              <i className="bi bi-envelope me-3" style={{ fontSize: '16px' }}></i>
              <span>contact@microbizmfb.com</span>
            </p>
            <p className="d-flex align-items-center" style={{ fontSize: '14px', justifyContent: 'flex-start' }}>
              <i className="bi bi-telephone me-2" style={{ fontSize: '16px' }}></i>
              <span>+234-814-636-4384</span>
            </p>
          </Col>

          <Col xs={{ order: 2 }}>
            <p className="fw-bold" style={{ fontSize: '14px' }}>Company</p>
            <p className="mb-3" style={{ fontSize: '14px' }}>About</p>
            <p className="mb-3" style={{ fontSize: '14px' }}>Team</p>
            <p style={{ fontSize: '14px' }}>Careers</p>
          </Col>
          <Col xs={{ order: 3 }}>
            <p className="fw-bold" style={{ fontSize: '14px' }}>Personal</p>
            <p className="mb-3" style={{ fontSize: '14px' }}>Save Money</p>
            <p className="mb-3" style={{ fontSize: '14px' }}>Take Loan</p>
          </Col>
          <Col xs={{ order: 4 }} className="text-center">
            <p className="fw-bold mb-3" style={{ fontSize: '14px' }}>Our Socials</p>
            <div className="d-flex justify-content-center align-items-center">
              <i className="bi bi-facebook me-3" style={{ fontSize: '16px' }}></i>
              <i className="bi bi-instagram me-3" style={{ fontSize: '16px' }}></i>
              <i className="bi bi-twitter" style={{ fontSize: '16px' }}></i>
            </div>
          </Col>
        </Row>

        <hr />
        <p style={{ fontSize: '12px', margin: '1rem 0', textAlign: 'left' }}>
          © 2022 Microbiz MFBank Limited (RC1876581). All rights reserved. Microbiz MFBank Limited is registered with the Corporate Affairs Commission (CAC) with the registration number RC1876581 and fully licensed by The Central Bank of Nigeria (CBN), and insured by The Nigeria Deposit Insurance Corporation (NDIC).
          “Microbiz MFB” and “Microbiz MFBank" are trademarks of Microbiz Microfinance Bank Limited, Maitama Ext, Abuja: Suites 13-16, New Mpape Modern Market, Abuja, Nigeria.
          All text, graphics, audio files, code, downloadable material, and other works on this website are the copyrighted works of Microbiz MFBank Limited. All Rights Reserved. Any unauthorized redistribution or reproduction of any copyrighted materials on this website is strictly prohibited. Other products and company names are trademarks of their respective owners. This website contains simulated images; actual appearance may vary.
        </p>
      </Container>
    </footer>
  );
}

export default Footer;

import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import logo from './pictures/UBA.jpg';
import pic1 from './pictures/gr2.jpg'; // Correct path
import pic2 from './pictures/gr3.jpg'; // Correct path


import logo1 from './pictures/remita-logo.jpg';
import logo2 from './pictures/zenith-bank-logo.jpg';
import logo3 from './pictures/fidelity-bank-logo.jpg';
import logo4 from './pictures/nibss-logo.jpg';
import logo5 from './pictures/access-bank-logo.jpg';
import logo6 from './pictures/partners-worldwide-logo.jpg';
import logo7 from './pictures/crc-cb-logo.jpg';
import logo8 from './pictures/first-central-logo (1).jpg';
import logo9 from './pictures/uba-logo.jpg';
import logo10 from './pictures/peak-mfi-logo.jpg';

function SMELoan() {
  return (
    <div>
    <div className='herosection' style={{ backgroundColor: '#fff', padding: '0', marginTop: '100px' }}>
      <Container fluid className='d-flex flex-column'>
        {/* Intro Section */}
        <Row className='align-items-center' style={{ paddingLeft: '6rem', paddingRight: '6rem', paddingBottom: '1rem', paddingTop: '5rem' }}>
          <Col md={6} className='text-white d-flex flex-column align-items-start' style={{ padding: '1rem' }}>
            <h1 style={{ textAlign: 'left', lineHeight: 1.3, color: 'black' }} className='fs-3'>
              Unlock Your Business Potential with Our <span style={{ color: 'red' }}>SME Loans</span> 
            </h1>
            <p style={{ textAlign: 'left', lineHeight: 1.7, color: 'black' }}>
            Fuel your business growth with our specialized SME loan products, designed to provide the financial support your business needs to thrive              </p>
            <div className='d-flex flex-md-row justify-content-center' style={{ gap: '1rem' }}>
              {/* Add your content here */}
            </div>
            <div className='d-flex flex-wrap justify-content-center' style={{ gap: '1rem', marginTop: '1rem' }}>
              {/* Add your content here */}
            </div>
          </Col>

          {/* Image Carousel */}
          <Col md={6} className='d-flex align-items-center justify-content-center'>
            <Carousel 
              interval={3000} // Adjust interval time (in milliseconds) as needed
              indicators={false} // Optionally remove the default indicators (dots)
              controls={false} // Optionally remove the default controls (arrows)
              fade // Optional: adds a fade effect between slides
            >
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={logo}
                  alt="Slide 1"
                  style={{
                    width: '80%',
                    maxWidth: '60rem', // Max width to maintain the size you want on larger screens
                    height: 'auto',
                    objectFit: 'contain',
                    transform: 'scale(1.08)',
                    transformOrigin: 'center',
                  }}
                />
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={pic2}
                  alt="Slide 2"
                  style={{
                    width: '80%',
                    maxWidth: '60rem', // Max width to maintain the size you want on larger screens
                    height: 'auto',
                    objectFit: 'contain',
                    transform: 'scale(1.08)',
                    transformOrigin: 'center',
                  }}
                />
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={pic1} // Repeat the first image if you want to show it again
                  alt="Slide 3"
                  style={{
                    width: '80%',
                    maxWidth: '60rem', // Max width to maintain the size you want on larger screens
                    height: 'auto',
                    objectFit: 'contain',
                    transform: 'scale(1.08)',
                    transformOrigin: 'center',
                  }}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
    <div>
      <Container>
      <div className="text-center mb-4">
    <div
      className="text-center mb-3"
      style={{
        maxWidth: '150px',
        height: 'auto',
        padding: '10px',
        borderRadius: '3px',
        opacity: '0.9',
        margin: 'auto',
        backgroundColor: '#F4F4F4',
        
      }}
    >
      <h1 style={{ fontSize: '10px', margin: '0', color: '#095390',}}>SME loan plans</h1>
    </div>
    <p style={{ fontSize: '18px', margin: '0' }}>Discover the options that best suit your goals</p>
  </div>
  <div style={{ marginBottom: '2rem' }}>
<div style={{ display: 'flex', alignItems: 'center', gap: '5rem', justifyContent: 'center' }}>
  <div style={{ display: 'flex', alignItems: 'center', maxWidth: '50%' }}>
    <span style={{ fontSize: '18px', marginRight: '0.5rem' }}>•</span>
    <p style={{ fontSize: '18px', margin: 0 }}>Asset Acquisition Loan</p>
  </div>
  <div style={{ maxWidth: '35%', fontSize: '14px' }}>
  Finance the purchase of essential business assets with flexible repayment terms to enhance your operational capacity.  </div>
</div>
</div>

<div style={{ marginBottom: '2rem' }}>
<div style={{ display: 'flex', alignItems: 'center', gap: '10rem', justifyContent: 'center' }}>
  <div style={{ display: 'flex', alignItems: 'center', maxWidth: '50%' }}>
    <span style={{ fontSize: '18px', marginRight: '0.5rem' }}>•</span>
    <p style={{ fontSize: '18px', margin: 0 }}>Overdraft</p>
  </div>
  <div style={{ maxWidth: '35%', fontSize: '14px' }}>
  Maintain your business’s cash flow and cover unexpected expenses with our convenient overdraft facility.
      </div>
</div>
</div>

<div style={{ marginBottom: '3rem' }}>
<div style={{ display: 'flex', alignItems: 'center', gap: '8rem', justifyContent: 'center' }}>
  <div style={{ display: 'flex', alignItems: 'center', maxWidth: '50%' }}>
    <span style={{ fontSize: '18px', marginRight: '0.5rem' }}>•</span>
    <p style={{ fontSize: '18px', margin: 0 }}>BizPlus Loan</p>
  </div>
  <div style={{ maxWidth: '35%', fontSize: '14px' }}>
  Access substantial funding to expand and scale your business operations, tailored to meet larger financial requirements.  </div>
</div>
</div>

<div>
<div style={{ display: 'flex', alignItems: 'center', gap: '4rem', justifyContent: 'center' }}>
  <div style={{ display: 'flex', alignItems: 'center', maxWidth: '30%' }}>
    <span style={{ fontSize: '18px', marginRight: '0.5rem' }}>•</span>
    <p style={{ fontSize: '18px', margin: 0 }}>Female Merchant Loan</p>
  </div>
  <div style={{ maxWidth: '35%', fontSize: '14px' }}>
  Empower female entrepreneurs with financial solutions designed to support business growth and sustainability. </div>
</div>
</div>



<div className='partners' style={{paddingTop: '2%'}}>
    <Container className="features" style={{ justifyContent: 'center', alignItems: 'center',marginBottom: '1rem', marginTop:'2rem' }}>
    <Row className="mb-4" style={{ justifyContent: 'center' }}>
        <p  style={{
            color: '#095390',
            backgroundColor: '#F4F4F4',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>Our Partners</p>
      </Row>
        <Row className="text-center mb-4">
          <Col>
            <h5>Trusted Partnerships for Exceptional Service</h5>
          </Col>
        </Row>
      <Row className="justify-content-between mb-4">
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo1} alt="Partner 1" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo2} alt="Partner 2" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo3} alt="Partner 3" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo4} alt="Partner 4" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo5} alt="Partner 5" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
      </Row>
      <Row  className="justify-content-between">
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo6} alt="Partner 6" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo7} alt="Partner 7" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo8} alt="Partner 8" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo9} alt="Partner 9" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo10} alt="Partner 10" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
      </Row>
      </Container>
    </div>
    <div className='FAQ'>

    </div>
    <div>

    </div>


      </Container>
    </div>
  </div>
  );
}

export default SMELoan;

import React from 'react';
import { Container, Row, Col, Card, Form, Button, Carousel} from 'react-bootstrap';
import logo from './pictures/UBA.jpg';

import { ReactComponent as Transfer } from './pictures/switch-horizontal.svg';


import pic1 from './pictures/pic.jpg';
import pic2 from './pictures/gr3.jpg';




import image1 from './pictures/us.jpeg';
import image2 from './pictures/md.jpg';
import image3 from './pictures/wa.jpeg';
import image4 from './pictures/oc.jpeg';
import image5 from './pictures/tr.jpeg';
import image6 from './pictures/ed 2.jpg';

import image7 from './pictures/isibor.jpg';
import image8 from './pictures/felix.jpg';
import image9 from './pictures/amara.jpg';
import image10 from './pictures/hit.jpg';
import image11 from './pictures/kc.jpeg';
import image12 from './pictures/is.jpg';
import image13 from './pictures/hr.jpg';


import logo1 from './pictures/remita-logo.jpg';
import logo2 from './pictures/zenith-bank-logo.jpg';
import logo3 from './pictures/fidelity-bank-logo.jpg';
import logo4 from './pictures/nibss-logo.jpg';
import logo5 from './pictures/access-bank-logo.jpg';
import logo6 from './pictures/partners-worldwide-logo.jpg';
import logo7 from './pictures/crc-cb-logo.jpg';
import logo8 from './pictures/first-central-logo (1).jpg';
import logo9 from './pictures/uba-logo.jpg';
import logo10 from './pictures/peak-mfi-logo.jpg';



// Updated card data with image paths, titles, and descriptions
const cardData = [
  { image: image1, title: 'Danjuma Usman', text: 'Chairman, Board of Directors' },
  { image: image2, title: 'Joe Onyeabor', text: 'Managing Director/CEO' },
  { image: image3, title: 'Wakama Sotonye', text: 'Director' },
  { image: image4, title: 'Ochoche Owoicho', text: 'Director' },
  { image: image5, title: 'Teryila Ubwa', text: 'Independent Director' },
  { image: image6, title: 'Abiodun Yusuph', text: 'Board Secretary/CFO' }
];

const cardData1 = [
  { image: image7, title: 'Isibor Okhihie', text: 'Head, Internal Audit & Control' },
  { image: image8, title: 'Felix Ekemini Pius', text: 'Head, Risk Management' },
  { image: image9, title: 'Amarachi Ihekwoaba', text: 'Head, Banking Operations' },
  { image: image10, title: 'Umar Aliyu Hajji', text: 'Head, Information Technology' },
  { image: image11, title: 'Kelechi Kalu', text: 'Head, Support Services' },
  { image: image12, title: 'Isaac P Oloche', text: 'Head Innovation' },
  { image: image13, title: 'Adaorah Orkafansi', text: 'Head, Human Resources' },
];

function Company () {
  return (
    <div>
          
    <div className='secondsection' style={{ paddingRight: '8%', paddingLeft: '8%', marginTop: '100px', marginBottom: '100px' }}>
      <Container fluid style={{ gap: '40px' }}>
        <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%' }}>
          
          <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
            <h1 style={{ textAlign: 'left', color: 'black', lineHeight: 1.3 }} className=''>
            A Bank with a <span style={{ color: 'red' }}>touch of class!</span> 
            </h1>
            <p style={{ textAlign: 'left', color: 'black', lineHeight: 1.7 }}>
            At Microbiz, we believe in excellence in service delivery while providing access to financial services for the people at the base of the economic pyramid.            </p>
            <div className='d-flex flex-column flex-md-row' style={{ gap: '1rem' }}>
              <Button style={{ backgroundColor: '#095390', color: '#ffffff', border: '#095390', width: '10rem' }}>
              Bank with us
              </Button>
            </div>
          </Col>

          <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
          <Carousel 
      interval={3000} // Adjust interval time (in milliseconds) as needed
      indicators={false} // Optionally remove the default indicators (dots)
      controls={false} // Optionally remove the default controls (arrows)
      fade // Optional: adds a fade effect between slides
    >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={logo}
          alt="Slide 1"
          style={{
            width: '80%',
            maxWidth: '60rem', // Max width to maintain the size you want on larger screens
            height: 'auto',
            objectFit: 'contain',
            transform: 'scale(1.08)',
            transformOrigin: 'center',
          }}
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={pic2}
          alt="Slide 2"
          style={{
            width: '80%',
            maxWidth: '60rem', // Max width to maintain the size you want on larger screens
            height: 'auto',
            objectFit: 'contain',
            transform: 'scale(1.08)',
            transformOrigin: 'center',
          }}
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={pic1} // Repeat the first image if you want to show it again
          alt="Slide 3"
          style={{
            width: '80%',
            maxWidth: '50rem', // Max width to maintain the size you want on larger screens
            height: 'auto',
            objectFit: 'contain',
            transform: 'scale(1.08)',
            transformOrigin: 'center',
          }}
        />
      </Carousel.Item>
    </Carousel>
            
          </Col>
        </Row>
      </Container>
    </div>

      {/* Cards Section */}
      <div className="text-dark text-center py-5 my-5" style={{ backgroundColor: '#F8F8F8' }}>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={5} className="mb-4 d-flex justify-content-center">
              <Card style={{ width: '22rem', height: '8rem' }} className="mx-2">
                <Card.Body className="text-start">
                  <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: '18px' }}>
                    Vision
                  </Card.Subtitle>
                  <Card.Text style={{ fontSize: '15px' }}>
                    To be the foremost Digital Microfinance Bank in Nigeria.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={5} className="mb-4 d-flex justify-content-center">
              <Card style={{ width: '22rem', height: '8rem' }} className="mx-2">
                <Card.Body className="text-start">
                  <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: '18px' }}>Mission</Card.Subtitle>
                  <Card.Text style={{ fontSize: '14px' }}>
                    An innovative Digital Microfinance Bank driven by passion and commitment to provide life-changing solutions towards sustainable empowerment for everyday people.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Core Values Section */}
      
    <Container className="features" style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: '6rem', paddingRight: '6rem', marginBottom: '1rem' }}>
    <Row className="mb-4">
        <p  style={{
            color: '#095390',
            backgroundColor: '#F4F4F4',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>Our core values</p>
      </Row>
      <Row className="text-center mb-4">
        <Col>
          <h5>Guiding principles that define our purpose</h5>
        </Col>
      </Row>
      <Row style={{ padding: '1.5%', backgroundColor: '#1C2F5A', borderRadius: '10px' }} className="justify-content-center">
        <Col xs={12} sm={6} md={5} lg={4} className="mb-4">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Integrity</p>
            <span style={{ fontSize: '0.8rem' }}>Our work ethics & business principles are aligned with our service promise to all stakeholders.</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4} className="mb-4">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Sustainability</p>
            <span style={{ fontSize: '0.8rem' }}>Our processes, business model, and products are designed for the present and future generations</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4} className="mb-4">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '3rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' 
              
            }}>

              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Innovation</p>
            <span style={{ fontSize: '0.5rem' }}>Our standards include better ways of doing things, in order to amaze our customers with new products, initiatives & excellent customer service.</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4} className="mb-4">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Discipline</p>
            <span style={{ fontSize: '0.7rem' }}>We develop the culture and mindset of execution, making us focus on our goals and service delivery, inline with our business ethics and standards.</span>
          </div>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4}className="mb-4">
          <div style={{ padding : '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
            <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
              <Transfer width="24" height="24" />
            </div>
            <p style={{ marginBottom: '3%' }}>Excellence</p>
            <span style={{ fontSize: '0.8rem' }}>We delight our customers with our products and services, using care, diligence, professionalism, and best practice.</span>
          </div>
        </Col>
        {/* Repeat for other features */}
      </Row>
    </Container>

      {/* Team Section */}
      <div className='d-flex justify-content-center'>
  <Container style={{ width: '903px', padding: '40px 0', opacity: '1' }} className="my-5">
  <Row className="mb-4" style={{ justifyContent: 'center' }}>
        <p  style={{
            color: '#095390',
            backgroundColor: '#F4F4F4',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>Our team</p>
      </Row>
        <Row className="text-center mb-4">
          <Col>
            <p>Board of Directors</p>
          </Col>
        </Row>
    <Row className="g-1 justify-content-center"> {/* Center the columns */}
      {cardData.map((data, index) => (
        <Col
          xs={12}
          md={4}
          className="d-flex justify-content-center mb-4"
          key={index}
        >
          <Card
            style={{
              width:'95%',
              padding: '1rem', // Reduced padding
              borderRadius: '10px',
              border: '1px solid #000',
              opacity: '0.9',
              boxShadow: 'none', // Remove box shadow
              border: ' solid',
                    borderWidth:'1px',
                    borderColor:'#e6e6e6',
            }}
            className="d-flex flex-column align-items-center justify-content-center "
          >
           
              <img
                src={data.image}
                alt={`Image ${index + 1}`}
                style={{
                  width: '100%',
                  height: '10rem',
                  objectFit: 'cover',
                  borderRadius: '3px',
                }}
              />
            
            <Card.Body style={{ textAlign: 'left', paddingTop: '20px', paddingLeft: '10px' }}> {/* Reduced padding */}
              <Card.Title style={{ fontSize: '15px', fontWeight: 'bold' }}>{data.title}</Card.Title>
              <Card.Text style={{ fontSize: '12px' }}>{data.text}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>

    <div className='d-flex justify-content-center'>
      <Container style={{ width: '903px', padding: '1px 0', opacity: '1' }} className="my-5">
        <div className="text-center mb-4">
          <p style={{ fontSize: '18px', margin: '0' }}>Management Team</p>
        </div>
        <Row className="g-1"> {/* Center the columns */}
          {cardData1.map((data, index) => (
            <Col
              xs={12}
              md={4}
              className="d-flex justify-content-center mb-4"
              key={index}
            >
              <Card
                style={{
                  width:'95%',
                  padding: '1rem', // Reduced padding
                  borderRadius: '10px',
                  border: '1px solid #000',
                  opacity: '0.9',
                  boxShadow: 'none', // Remove box shadow
                  border: ' solid',
                        borderWidth:'1px',
                        borderColor:'#e6e6e6',
                }}
                className="d-flex flex-column align-items-center"
              >
                
                <img
                src={data.image}
                alt={`Image ${index + 1}`}
                style={{
                  width: '100%',
                  height: '10rem',
                  objectFit: 'cover',
                  borderRadius: '3px',
                }}
              />
              
                 <Card.Body style={{ textAlign: 'left', paddingTop: '20px', paddingLeft: '10px' }}> {/* Reduced padding */}
                  <Card.Title style={{ fontSize: '15px', fontWeight: 'bold' }}>{data.title}</Card.Title>
                  <Card.Text style={{ fontSize: '12px' }}>{data.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>




        {/* New Row for cardData1 */}
        <div className="text-center mb-4">
        <Row className="mb-4" style={{ justifyContent: 'center' }}>
        <p  style={{
            color: '#095390',
            backgroundColor: '#F4F4F4',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>Careers</p>
      </Row>
        <Row className="text-center mb-4">
          <Col>
            
          </Col>
        </Row>
          
        </div>
        

        <Container>
      <Row className="my-4">
        <Col xs={12} md={4} className="mb-3">
          <Form.Group controlId="formSearch">
            <Form.Label>Search roles</Form.Label>
            <Form.Control type="text" placeholder="Search roles..." />
          </Form.Group>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Form.Group controlId="formDropdown1">
            <Form.Label></Form.Label>
            <Form.Control as="select">
              <option>Job Title</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Form.Group controlId="formDropdown2">
            <Form.Label></Form.Label>
            <Form.Control as="select">
              <option>Location</option>
              <option>Option B</option>
              <option>Option C</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Container>

      </Container>
    </div>
        </Container>
      </div>
      <div>
      <div className="text-center mb-4">
     
     
     
      

        
      </div>
    </div>

    <div className='partners' style={{paddingTop: '2%'}}>
    <Container className="features" style={{ justifyContent: 'center', alignItems: 'center',marginBottom: '1rem' }}>
    <Row className="mb-4" style={{ justifyContent: 'center' }}>
        <p  style={{
            color: '#095390',
            backgroundColor: '#F4F4F4',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>Our Partners</p>
      </Row>
        <Row className="text-center mb-4">
          <Col>
            <h5>Trusted Partnerships for Exceptional Service</h5>
          </Col>
        </Row>
      <Row className="justify-content-between mb-4">
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo1} alt="Partner 1" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo2} alt="Partner 2" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo3} alt="Partner 3" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo4} alt="Partner 4" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo5} alt="Partner 5" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
      </Row>
      <Row  className="justify-content-between">
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo6} alt="Partner 6" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo7} alt="Partner 7" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo8} alt="Partner 8" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo9} alt="Partner 9" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
        <Col xs={6} md={4} lg={2} className="d-flex justify-content-center mb-4">
          <img src={logo10} alt="Partner 10" style={{ width: '45%', height: 'auto', margin: '1rem' }} />
        </Col>
      </Row>
      </Container>
    </div>
    <div className='FAQ'>

    </div>
    <div>

    </div>


<div >

          <Container>
          
          </Container>


    </div>
    </div>
  );
}

export default Company;

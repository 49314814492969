import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import logo from './pictures/gold1.jpg';
import pic1 from './pictures/gold2.jpeg'; // Correct path
import pic2 from './pictures/gold 3.jpg'; // Correct path

function RetailLoan() {
  return (
    <div>
      <div className='herosection' style={{ backgroundColor: '#fff', padding: '0', marginTop: '100px' }}>
        <Container fluid className='d-flex flex-column'>
          {/* Intro Section */}
          <Row className='align-items-center' style={{ paddingLeft: '6rem', paddingRight: '6rem', paddingBottom: '1rem', paddingTop: '5rem' }}>
            <Col md={6} className='text-white d-flex flex-column align-items-start' style={{ padding: '1rem' }}>
              <h1 style={{ textAlign: 'left', lineHeight: 1.3, color: 'black' }} className='fs-3'>
              Empower your business with our  <span style={{ color: 'red' }}> retail loans </span> 
              </h1>
              <p style={{ textAlign: 'left', lineHeight: 1.7, color: 'black' }}>
              Tailored financial solutions to help your business grow, manage cash flow, and seize new opportunities. Enjoy competitive rates, flexible terms, and fast approval                </p>
              <div className='d-flex flex-md-row justify-content-center' style={{ gap: '1rem' }}>
                {/* Add your content here */}
              </div>
              <div className='d-flex flex-wrap justify-content-center' style={{ gap: '1rem', marginTop: '1rem' }}>
                {/* Add your content here */}
              </div>
            </Col>

            {/* Image Carousel */}
            <Col md={6} className='d-flex align-items-center justify-content-center'>
              <Carousel 
                interval={3000} // Adjust interval time (in milliseconds) as needed
                indicators={false} // Optionally remove the default indicators (dots)
                controls={false} // Optionally remove the default controls (arrows)
                fade // Optional: adds a fade effect between slides
              >
                <Carousel.Item>
                <img
                className="d-block w-100"
                src={logo}
                alt="Slide 1"
                style={{
                  width: '80%',
                  maxWidth: '60rem', // Max width to maintain the size you want on larger screens
                  maxHeight: '20rem', // Specify maxHeight to limit the height
                  //height: 'auto', // Maintain aspect ratio
                  objectFit: 'contain', // Ensure the image fits within the bounds
                  transform: 'scale(1.08)',
                  transformOrigin: 'center',
  }}
/>

                  
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={pic2}
                    alt="Slide 2"
                    style={{
                      width: '80%',
                      maxWidth: '60rem', // Max width to maintain the size you want on larger screens
                      maxHeight: '20rem', // Specify maxHeight to limit the height
                      objectFit: 'contain',
                      transform: 'scale(1.08)',
                      transformOrigin: 'center',
                    }}
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={pic1} // Repeat the first image if you want to show it again
                    alt="Slide 3"
                    style={{
                      width: '80%',
                      maxWidth: '60rem', // Max width to maintain the size you want on larger screens
                      maxHeight: '20rem', // Specify maxHeight to limit the height
                      objectFit: 'contain',
                      transform: 'scale(1.08)',
                      transformOrigin: 'center',
                    }}
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>


      <div>
        <Container style={{paddingTop:'10rem',position:''}}>
        <div className="text-center mb-4">
      <div
        className="text-center mb-3"
        style={{
          maxWidth: '150px',
          height: 'auto',
          padding: '10px',
          borderRadius: '3px',
          opacity: '0.9',
          margin: 'auto',
          backgroundColor: '#F4F4F4',
          
        }}
      >
        <h1 style={{ fontSize: '10px', margin: '0' }}>Retail loan plans</h1>
      </div>
      <p style={{ fontSize: '18px', margin: '0' }}>Explore our diverse loan options to find the best fit for your needs</p>
    </div>
    <div style={{ marginBottom: '2rem' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '5rem', justifyContent: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '50%' }}>
      <span style={{ fontSize: '18px', marginRight: '0.5rem' }}>•</span>
      <p style={{ fontSize: '18px', margin: 0 }}>Micro Group Loan</p>
    </div>
    <div style={{ maxWidth: '35%', fontSize: '14px' }}>
      Provides small groups with the financial support needed to collectively grow their businesses. Ideal for community-based business ventures aiming for collective prosperity
    </div>
  </div>
</div>

<div style={{ marginBottom: '2rem' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '5rem', justifyContent: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '50%' }}>
      <span style={{ fontSize: '18px', marginRight: '0.5rem' }}>•</span>
      <p style={{ fontSize: '18px', margin: 0 }}>Minimonie Loan</p>
    </div>
    <div style={{ maxWidth: '35%', fontSize: '14px' }}>
    Offers short-term financing solutions to small business owners, helping them manage cash flow and operational needs effectively. Perfect for addressing immediate business expenses.    </div>
  </div>
</div>

<div style={{ marginBottom: '2rem' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '5rem', justifyContent: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '50%' }}>
      <span style={{ fontSize: '18px', marginRight: '0.5rem' }}>•</span>
      <p style={{ fontSize: '18px', margin: 0 }}>Sabitrader Loan</p>
    </div>
    <div style={{ maxWidth: '35%', fontSize: '14px' }}>
    Tailored specifically for traders, this loan facilitates business expansion and inventory increase, enabling traders to scale their operations smoothly.    </div>
  </div>
</div>

<div>
  <div style={{ display: 'flex', alignItems: 'center', gap: '2rem', justifyContent: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '20%' }}>
      <span style={{ fontSize: '18px', marginRight: '0.5rem' }}>•</span>
      <p style={{ fontSize: '18px', margin: 0 }}>Widows Empowerment Loan</p>
    </div>
    <div style={{ maxWidth: '35%', fontSize: '14px' }}>
Focuses on providing financial assistance to widows, helping them start or grow their businesses and achieve financial independence.    </div>
  </div>
</div>



        </Container>
      </div>
    </div>
  );
}

export default RetailLoan;
